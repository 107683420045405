// [lyric, startTime, endTime, leftMargin (px)]
const RoundAndBlue = [
    ["You always gotta be ahead of the curve", 1,  34.4, 0],
    ["There are cursive prices", 5.2,  34.4, 10],
    ["For what you deserve", 8,  34.4, 20],
    ["Organizing all the screws", 11,  34.4, 30],
    ["For an empty hearted muse", 13.2,  34.4, 40],
    ["They say melancholy's such a fragrant place", 18, 34.4, 53],
    ["But if you wanna paint with it you've gotta", 22, 34.4, 63],
    ["Pick up the pace", 25.7, 34.4, 73],
    ["Heard the corner store's", 28.3, 34.4, 86],
    ["Got exactly what you're looking for", 30.3, 34.4, 96],
    ["Who would've thought it's dangerous:", 34.4, 61, 0],
    ["• To design a cure that makes the well-kept vagrant", 38, 61, 11],
    ["• To entertain the thought of concerts on the pavement", 42, 61, 25],
    ["• To slap a brand on it and make it seem less flagrant", 46.3, 61, 39],
    ["It's round and blue", 52.1, 61, 60],
    ["(Bum bum baa du dum)", 54.3, 61, 70],
    ["Round and blue", 57, 61, 80],
    ["(Bum bum baa dum)", 58.6, 61, 90],
    ["Round", 61.3, 68.5, 0],
    ["And", 61.9, 68.5, 10],
    ["Blue", 62.3, 68.5, 20],
    ["(Bum bum baa du dum)", 62.8, 68.5, 40],
    ["Ahh...", 65.5, 68.5, 60],
    ["Now everyday is", 68.5, 86, 0],
    ["Blue", 70.7, 86, 10],
    ["Round", 74.3, 86, 30],
    ["And", 74.9, 86, 40],
    ["Blue", 75.3, 86, 50],
    ["Just another way to", 77, 85, 70],
    ["Make it through", 78.5, 85.3, 80],
    ["Round and blue", 78.45, 85.6, 100],
    ["Round and blue", 80.7, 85.9, 110],
    ["Ooh...", 83, 86, 120],
    ["Such a purpose-driven alloy", 88.5, 105, 0],
    ["Stuck in the notion", 92.6, 105, 10],
    ["Of artificial motion", 94.3, 105, 20],
    ["Has it even crossed your mind to", 97, 105, 40],
    ["Give thanks and release", 100.6, 105, 50],
    ["To the earth that gave you peace?", 102.8, 105, 60],
    ["Not even", 105, 114.4, 0],
    ["e", 105.7, 114.4, 40],
    ["A moment", 107, 114.4, 10],
    ["5", 107.7, 114.4, 50],
    ["For silence", 109.2, 114.4, 20],
    ["0", 110, 114.4, 60],
    ["2", 112, 114.4, 70],
    ["(wow a guitar solo)", 114.4, 129.6, 0],
    ["(these backing vocals sucked to record btw)", 118.6, 129.6, 20],
    ["(people were being loud outside)", 123, 129.6, 40],
    ["(the woes of a bedroom musician)", 127.4, 129.6, 60],
    ["Who would've thought it's dangerous", 129.6, 146.5, 0],
    ["To walk in late on your arraignment", 133.2, 146.5, 10],
    ["For tripping up on some lab coat casino payments", 137.2, 146.5, 20],
    ["Who is it here who's really hurting from an ailment?", 141.6, 146.5, 40],
    ["Ahh...", 146.5, 164.5, 0],
    ["Ya deebee dooba", 148, 164.5, 10],
    ["Di dai", 149.4, 164.5, 20],
    ["Ya dibbi dai...", 150.2, 164.5, 30],
    ["There are cursive prices for what you deserve", 152.4, 164.5, 50],
    ["Organizing all the screws for an empty hearted muse", 157.6, 164.5, 60],
    ["Every day will feel the same -", 164.5, 181, 0],
    ["Blood through crystal-clear veins", 167.7, 181, 10],
    ["These translucent amber bottles", 169.9, 181, 30],
    ["That breed primetime TV models", 172, 181, 40],
    ["Will erode the castles", 174.1, 181, 50],
    ["Floating in the air", 176.8, 181, 60],
    ["Now everyday is blue", 181, 199, 0],
    ["Round and blue", 186.7, 199, 10],
    ["Just another way", 189.3, 199, 60],
    ["To make it", 190.6, 199, 70],
    ["Round and blue", 191, 199, 20],
    ["Through", 192.19, 199, 80],
    ["Round and blue", 193.2, 199, 30],
    ["Ooh...", 195.4, 199, 40],
    ["Everyday is", 199, 207.5, 0],
    ["Round", 200.8, 207.5, 10],
    ["And", 201.5, 207.5, 20],
    ["Blue", 202, 207.5, 30],
    ["Two times a day it's", 203.3, 207.5, 50],
    ["Round", 205.1, 207.5, 60],
    ["And", 205.6, 207.5, 70],
    ["Blue", 206.2, 207.5, 80],
    ["It doesn't come in just", 207.5, 219, 0],
    ["Round", 209.45, 219, 10],
    ["And", 209.95, 219, 20],
    ["Blue", 210.45, 219, 30],
    ["But in the end it's all up to you", 211.9, 219, 50],
    ["You", 216, 219, 60],
    ["You", 217.1, 219, 70]
]

export default RoundAndBlue;


