const Vignette = [
    ["Idyllic scenes on TV screens", 6.5, 30, 0],
    ["Makes this feel heaven-sent", 9, 30, 10],
    ["Whoever thought it might happen for us?", 12.7, 30, 30],
    ["A treasure map fell on my lap", 18.5, 30, 50],
    ["Could you help me mark an X on the spot?", 21, 30, 60],
    ["Pastiche comedy that's taken too far", 24.5, 30, 80],
    ["", 30, 30, 0],
    ["Frolic as the silver hours pass", 32.8, 42, 0],
    ["And leave", 38.5, 42, 10],
    ["", 42, 42, 0],
    ["Listless and restless", 44.9, 68.7, 0],
    ["I saunter down", 47.1, 68.7, 10],
    ["The neon streets", 48.6, 68.7, 20],
    ["Shopping for whatever sticks", 50.8, 68.7, 30],
    ["Makes chemicals rush", 53.3, 68.7, 40],
    ["Didn't I promise to play nice with affection", 56.8, 68.7, 60],
    ["Not dart for directions with porcelain trees?", 62, 68.7, 70],
    ["Love", 68.7, 80.6, 0],
    ["Is ", 70.3, 80.6, 10],
    ["Not ", 71.6, 80.6, 20],
    ["A ", 73.2, 80.6, 30],
    ["Game", 74.6, 80.6, 40],
    ["Switching out the pieces on the board", 75, 80.6, 60],
    ["Will never cancel out the pain that you afford", 77.2, 80.6, 70],
    ["Stormy ", 80.6, 95.5, 0],
    ["Weather", 83.4, 95.5, 10],
    ["Came", 86.4, 95.5, 20],
    ["Limerence and blindfolds", 87, 95.5, 60],
    ["And cleared", 88, 95.5, 30],
    ["On tightropes", 89, 95.5, 70],
    ["Can leave you too soon", 90.5, 95.5, 80],
    ["Too soon", 91, 95.5, 40],
    ["", 95.5, 95.5, 0],
    ["I'm trying", 96, 126, 0],
    ["I'm trying", 100, 126, 10],
    ["I'm trying", 104, 126, 20],
    ["Not to throw it all away", 112.8, 126, 40],
    ["And fly free", 118.8, 126, 50],
    ["", 126, 126, 0],
    ["It could be fright", 136.8, 160.3, 0],
    ["Or appetite", 138.1, 160.3, 10],
    ["Maybe all the things that keep me up at night", 139.7, 160.3, 20],
    ["They're subliminally", 143, 160.3, 30],
    ["Pointing and laughing at me", 145.2, 160.3, 40],
    ["It seems", 151, 160.3, 60],
    ["That I'm just", 153.8, 160.3, 70],
    ["Trying to stop", 157.5, 160.3, 80],
    ["All the floorboard gazing", 160.3, 172.5, 0],
    ["All the morning dazing", 162, 172.5, 10],
    ["Polyester freezing", 163.3, 172.5, 20],
    ["Scientific teasing", 164.8, 172.5, 30],
    ["Parking far to talk", 166.2, 172.5, 40,],
    ["A million mile walk", 167.4, 172.5, 50],
    ["While tracing all our paths", 168.9, 172.5, 60],
    ["With smile-colored chalk", 170.4, 172.5, 70],
    ["How could I build this alphabet", 172.5, 185.8, 0],
    ["A castle made of sand the shore might soon forget ", 174.8, 187.8, 10],
    ["Spending life tumbling", 178.6, 189.7, 20],
    ["After the next", 181, 191.7, 30],
    ["Vignette?", 183.4, 200.8, 40],
    ["", 185.8, 185.8, 0],
    ["", 187.8, 187.8, 0],
    ["", 189.7, 189.7, 0],
    ["", 191.7, 191.7, 0],
    ["", 200.8, 200.8, 0]

]

export default Vignette;
